<template>
  <div id="k-d-a-process-container">
    <k-d-a-process-header
      :str-title="
        m_arrProcesses[iProcessIndex]?.strHeaderTitle ?? m_arrProcesses[iProcessIndex]?.strMenuTitle
      "
      :img-src="m_arrProcesses[iProcessIndex]?.headerSrc"
    />

    <base-section space="76">
      <v-container class="pa-0">
        <v-row style="gap: 120px" no-gutters>
          <v-col v-if="!g_bLowerBr" cols="auto">
            <aside style="max-width: 300px">
              <nav>
                <ul style="list-style-type: none; padding: 0">
                  <li
                    v-for="(process, index) in m_arrProcesses"
                    :key="`process-menu-${process.strMenuTitle}`"
                    class="d-flex flex-column justify-start align-start mb-4 main-bw-400--text"
                    style="cursor: pointer; position: relative"
                    @click="iProcessIndex = index"
                  >
                    <div style="position: relative" class="full-width">
                      <p class="kda-ts-28pt wt-extrabold text-capitalize">
                        {{ process.strMenuTitle }}
                      </p>

                      <div
                        v-if="iProcessIndex === index"
                        style="position: absolute; height: 3px; width: 58px; bottom: 0; left: 0"
                        class="main-bw-400 rounded"
                      ></div>
                    </div>

                    <div
                      v-if="iProcessIndex === index"
                      class="black"
                      style="position: absolute; left: -24px; height: 100%; width: 1px"
                    ></div>
                  </li>
                </ul>
              </nav>

              <v-btn
                outlined
                color="main-bw-400"
                class="text-capitalize mt-4"
                @click="navToConsultations"
              >
                {{ m_strBook }}
              </v-btn>
            </aside>
          </v-col>

          <v-col>
            <v-window v-model="iProcessIndex" :vertical="!g_bLowerBr">
              <v-window-item
                v-for="(process, index) in m_arrProcesses"
                :key="`window-slider-process-container-${index + 1}`"
                eager
              >
                <h1 :class="`kda-ts-${g_bLowerBr ? '24' : '36'}pt wt-extrabold px-6 px-md-0`">
                  {{ process.strMenuTitle }}
                </h1>

                <base-img
                  :src="process.thumbnailSrc"
                  height="204"
                  contain
                  :position="g_bLowerBr ? 'center' : 'left'"
                  class="mt-2"
                />

                <component
                  :is="`p`"
                  :class="`mt-8 mt-md-10 kda-ts-${g_bLowestBr ? '12' : '16'}pt nunito px-6 px-md-0`"
                  v-html="process.htmlDesc"
                />

                <base-img
                  :src="process.bottomSrc"
                  class="mt-6 mt-md-10 mx-auto px-6 px-md-0"
                  max-width="540"
                  contain
                  :min-height="g_bLowerBr ? 180 : 320"
                />

                <v-row
                  v-if="iProcessIndex < m_arrProcesses.length - 1"
                  justify="end"
                  align="center"
                  :style="{ marginTop: g_bLowerBr ? '40px' : '80px' }"
                  class="px-6 px-md-0"
                >
                  <v-col cols="auto" class="main-bw-400--text">
                    <p
                      :class="`kda-ts-${
                        g_bLowerBr ? '16' : '20'
                      }pt nunito wt-bold text-decoration-underline ln-150`"
                    >
                      {{ m_strNext }}
                    </p>
                    <p :class="`kda-ts-${g_bLowerBr ? '24' : '40'}pt wt-bold`">
                      {{ m_arrProcesses[(iProcessIndex + 1) % m_arrProcesses.length].strMenuTitle }}
                    </p>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      icon
                      elevation="1"
                      class="main-bw-300"
                      :height="g_bLowerBr ? 52 : 72"
                      :width="g_bLowerBr ? 52 : 72"
                      @click="iProcessIndex++"
                    >
                      <v-icon color="white" large>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-container>
    </base-section>

    <k-d-a-book-banner v-if="iProcessIndex === m_arrProcesses.length - 1 && g_bLowerBr" />
  </div>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDAProcessContainer',

  components: {
    KDAProcessHeader: () => import('@/views/sections/KDAProcessHeader'),
    KDABookBanner: () => import('@/views/sections/KDABookBanner')
  },

  mixins: [BaseBreakpoint],

  data() {
    return { iProcessIndex: 0 };
  },

  computed: {
    m_arrProcesses() {
      const result = [];

      for (let i = 1; i <= 7; i++) {
        result.push({
          strMenuTitle: this.$vuetify.lang.t(`$vuetify.kda.process.item${i}.title`),
          htmlDesc: this.$vuetify.lang.t(`$vuetify.kda.process.item${i}.desc`),
          headerSrc: require(`@/assets/photos/process-header-${i}.png`)
        });

        if (i === 1) {
          result[i - 1].strHeaderTitle = this.$vuetify.lang.t(
            `$vuetify.kda.process.item${i}.header`
          );
        }
      }

      for (let i = 0; i < result.length; i++) {
        result[i].thumbnailSrc = require(`@/assets/photos/process-thumbnail-${i + 1}.png`);
      }

      const bottomMapping = [1, 1, 2, 3, 4, 5, 5];
      for (let i = 0; i < bottomMapping.length; i++) {
        result[i].bottomSrc = require(`@/assets/photos/process-bottom-${bottomMapping[i]}.png`);
      }

      return result;
    },
    m_strNext() {
      return this.$vuetify.lang.t(`$vuetify.kda.process.next`);
    },
    m_strBook() {
      return this.$vuetify.lang.t(`$vuetify.kda.process.book`);
    }
  },

  methods: {
    navToConsultations() {
      window.gtag('event', 'click', {
        'event_label': 'Book A Chat'
      });

      this.$router.push('/consultations');
    }
  }
};
</script>
